export const MODULES = {
  clientManagement: {
    id: 'clientManagement',
    name: 'Administración de clientes',
    type: 'module',
    icon: 'fas fa-user-tie',
    redirectPath: '/dashboard/customers',
  },
  userManagement: {
    id: 'userManagement',
    name: 'Administración de usuarios',
    type: 'module',
    icon: 'fas fa-users',
    redirectPath: '/dashboard/users',
  },
  budgetAudit: {
    id: 'budgetAudit',
    name: 'Auditoría de presupuestos',
    type: 'module',
    icon: 'fas fa-search-dollar',
    redirectPath: '/dashboard/budget-audit',
  },
  audit: {
    id: 'audit',
    name: 'Auditoría',
    type: 'module',
    icon: 'fas fa-star',
    redirectPath: '/dashboard/audit',
  },
  sqlTalk: {
    id: 'sqlTalk',
    name: 'SQL Talk',
    type: 'module',
    icon: 'fas fa-terminal',
    redirectPath: '/dashboard/sql-talk',
  },
  pitagoras: {
    id: 'pitagoras',
    name: 'Pitágoras Add On',
    type: 'tool',
    icon: 'fas fa-cloud-download-alt',
    redirectPath: '/dashboard/home',
  },
};

export const ROLES = {
  admin: {
    id: 'admin',
    name: 'Admin',
  },
  viewer: {
    id: 'viewer',
    name: 'Viewer',
  },
};

export const PAGES = {
  audit: {
    base: '/',
    clients: '/audit',
    accounts: '/audit/accounts',
    history: '/audit/history',
    optiscore: '/audit/optiscore',
  },
};

export const MODULES_TYPES = {
  Module: 'module',
  Tool: 'tool',
  Customer: 'customer',
};
